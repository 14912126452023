
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Broadcaster } from '~/models/broadcaster'
import { Series } from '~/models/series'
import { Speaker } from '~/models/speaker'
import { Sermon } from '~/models/sermon'
import { FollowParams } from '~/store/users'
import { SaIconsType } from '~/assets/ts/types/icons'
import { SiteUserRequiredModal } from '~/assets/ts/utils/site'
import { followParams, initialFollowValue } from '~/assets/ts/utils/follow'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteButton, { ButtonSize } from '~/components/site/Button.vue'
import { ToastCustomApiError } from '~/assets/ts/utils/toast'
import { getApiError } from '~/assets/ts/utils/api'

export const FollowableProps = {
  speaker: {
    type: Object as PropType<Speaker>,
    default: undefined,
  },
  broadcaster: {
    type: Object as PropType<Broadcaster>,
    default: undefined,
  },
  series: {
    type: Object as PropType<Series>,
    default: undefined,
  },
  sermon: {
    type: Object as PropType<Sermon>,
    default: undefined,
  },
}

export default Vue.extend({
  name: 'FollowButton',
  components: { InlineIcon },
  props: {
    ...FollowableProps,
    /** Allows us to turn off the visual button elements and just show inline text */
    button: {
      type: Boolean,
      default: true,
    },
    /**
     * Shows a more explicit statement of what is being followed
     * @example "Follow this Broadcaster" instead of just "Follow"
     * */
    verbose: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
    icon: {
      type: Boolean,
    },
    removeText: {
      type: Boolean,
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'normal',
    },
    siteButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      waiting: false,
    }
  },
  computed: {
    loadingClass(): string {
      if (!this.isLoading || this.icon) return ''
      return 'loading-placeholder'
    },
    SiteButton() {
      return SiteButton
    },
    iconType(): SaIconsType {
      if (this.speaker) {
        return 'speaker'
      } else if (this.series) {
        return 'playlist'
      } else if (this.broadcaster) {
        return 'church'
      }
      return this.isFollowed ? 'bookmark-solid' : 'bookmark'
    },
    isSermon(): boolean {
      return !!this.sermon
    },
    hasInitialValue(): boolean {
      return this.initialValue !== undefined
    },
    initialValue(): boolean | undefined {
      return initialFollowValue(this.$props)
    },
    initialized(): boolean {
      return this.hasInitialValue || this.isFollowed !== undefined
    },
    followActionText(): string {
      if (!this.isFollowed) return this.$t('Follow').toString()
      return this.$t('Following').toString()
    },
    favoriteActionText(): string {
      if (!this.isFollowed) return this.$t('Bookmark').toString()
      return this.$t('Bookmarked').toString()
    },
    followAction(): TranslateResult {
      return this.isSermon ? this.favoriteActionText : this.followActionText
    },
    followText(): TranslateResult {
      if (!this.verbose) return this.followAction
      return this.$t('{followAction} this {followTarget}', {
        followAction: this.followAction,
        followTarget: this.followTarget,
      })
    },
    followTarget(): TranslateResult {
      if (this.speaker) {
        return this.$t('Speaker')
      } else if (this.broadcaster) {
        return this.$t('Broadcaster')
      } else if (this.series) {
        if (this.series.isPodcast) {
          return this.$t('Podcast')
        } else {
          return this.$t('Series')
        }
      } else if (this.isSermon) {
        return this.$t('Sermon')
      }
      return ''
    },
    isLoading(): boolean {
      return !this.sermon && !this.speaker && !this.broadcaster && !this.series
    },
    isFollowed(): boolean | undefined {
      if (!this.$users.loggedIn) return false
      if (!this.followParams) return false
      const store = this.$store.getters['users/followed'](this.followParams)
      return store ?? this.initialValue
    },
    followParams(): FollowParams | undefined {
      return followParams(this.$props)
    },
  },
  watch: {
    active() {
      this.getIsFollowed()
    },
  },
  methods: {
    clicked(): void {
      if (this.$users.loggedIn) {
        this.setFollow(!this.isFollowed)
      } else {
        SiteUserRequiredModal(this, this.followText)
      }
    },
    async setFollow(followed: boolean) {
      this.waiting = true
      try {
        await this.$store.dispatch('users/setFollowed', {
          ...this.followParams,
          followed,
        })
      } catch (e) {
        const limit = getApiError(e, this).message.match(/LIMIT=(\d+)/)
        ToastCustomApiError(e, this, [
          {
            error: 'LIMIT',
            message: this.$t(
              `Cannot {followAction} this {followTarget}. Maximum of {maxFollowCount} reached.`,
              {
                followAction: this.followAction.toString().toLowerCase(),
                followTarget: this.followTarget.toString().toLowerCase(),
                maxFollowCount: limit ? limit[1] : '?',
              }
            ),
          },
        ])
      }
      this.waiting = false
      this.$emit('change', this.isFollowed)
    },
    async getIsFollowed() {
      if (this.isFollowed !== undefined || this.waiting) return
      if (!this.$users.loggedIn) return
      if (!this.followParams) return
      this.waiting = true
      await this.$store.dispatch('users/getFollowed', this.followParams)
      this.waiting = false
    },
  },
})
