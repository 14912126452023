
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Broadcaster } from '~/models/broadcaster'
import FollowButton from '~/components/_general/FollowButton.vue'
import { BroadcasterDisplay, DateFormat } from '~/assets/ts/enums'
import { siteBroadcasterUrl } from '~/assets/ts/utils/urls'
import CoreElementWrapper from '~/components/_general/CoreElementWrapper.vue'
import BroadcasterImage from '~/components/_general/BroadcasterImage.vue'
import LiveIndicator from '~/components/_general/LiveIndicator.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import VerticalAlign from '~/components/_general/VerticalAlign.vue'
import SaLink from '~/components/_general/SaLink.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import { localizeDateTime } from '~/assets/ts/utils/date'
import { convertLineBreaks, truncateString } from '~/assets/ts/utils/strings'

interface ImageInfo {
  /** Tailwind Width Class */
  class: string
  width: number
}

export default Vue.extend({
  name: 'BroadcasterElement',
  components: {
    MarkdownElement,
    InlineIcon,
    SaLink,
    VerticalAlign,
    LoadingElement,
    LiveIndicator,
    BroadcasterImage,
    CoreElementWrapper,
    FollowButton,
  },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    display: {
      type: Number as PropType<BroadcasterDisplay>,
      default: BroadcasterDisplay.Standard,
    },
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    includeFollowButton: {
      type: Boolean,
    },
    includeInfoFollowButton: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
    },
    listDivider: {
      type: Boolean,
    },
    live: {
      type: Boolean,
      default: undefined,
    },
    includeJoinDate: {
      type: Boolean,
    },
    showSermonCount: {
      type: Boolean,
      default: true,
    },
    removeAboutLineBreaks: {
      type: Boolean,
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    imageInfo(): ImageInfo {
      if (this.largeExtended) {
        return {
          class: 'w-16 sm:w-24',
          width: 112,
        }
      }
      if (this.micro) {
        return {
          class: 'w-12',
          width: 48,
        }
      }
      return {
        class: 'w-16',
        width: 80,
      }
    },
    isLive(): boolean {
      return !!(this.live ?? this.broadcaster?.webcastInProgress)
    },
    simple(): boolean {
      return this.display === BroadcasterDisplay.Simple
    },
    micro(): boolean {
      return this.display === BroadcasterDisplay.Micro
    },
    infoColumn(): boolean {
      return this.display === BroadcasterDisplay.Info
    },
    expanded(): boolean {
      return this.display === BroadcasterDisplay.Expanded
    },
    standard(): boolean {
      return !this.infoColumn && !this.expanded
    },
    largeExtended(): boolean {
      return this.expanded && !this.small
    },
    wrap(): boolean {
      return (
        this.display === BroadcasterDisplay.StandardWrap ||
        this.display === BroadcasterDisplay.Expanded ||
        this.display === BroadcasterDisplay.Info
      )
    },
    shareTitle(): string {
      return this.broadcaster.shareTitle ?? ''
    },
    displayName(): string {
      return this.broadcaster ? this.broadcaster.displayName : ''
    },
    location(): string {
      return this.broadcaster ? this.broadcaster.location : ''
    },
    ministerString(): string | undefined {
      return this.broadcaster?.minister
    },
    broadcasterID(): string {
      return this.broadcaster ? this.broadcaster.id : ''
    },
    broadcasterUrl(): string {
      return siteBroadcasterUrl(this.broadcaster)
    },
    hasSermons(): boolean {
      if (!this.broadcaster) return false
      if (!this.broadcaster.numberOfSermons) return false
      return parseInt(this.broadcaster.numberOfSermons) > 0
    },
    sermonCount(): string {
      if (!this.broadcaster) return ''
      return this.broadcaster.numberOfSermons ?? ''
    },
    showLanguage(): boolean {
      return this.broadcaster?.english === false
    },
    joinedDate(): string {
      if (this.broadcaster && this.includeJoinDate && this.broadcaster.joined) {
        return localizeDateTime(
          this.broadcaster.joined,
          DateFormat.CompressedDate
        )
      } else {
        return ''
      }
    },
    aboutUs(): string | undefined {
      if (!this.broadcaster || !this.broadcaster.aboutUs) {
        return undefined
      }

      return truncateString(
        this.removeAboutLineBreaks
          ? convertLineBreaks(this.broadcaster.aboutUs, ' ')
          : this.broadcaster.aboutUs,
        400
      )
    },
  },
  mounted() {
    this.mounted = true
  },
})
